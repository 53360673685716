import { plmRequest } from '@admin/utils/http/axios'

enum Api {
  getMaterialCategoryFilter = '/api/material/material_category/filter_list',
  getMaterialCategoryDetailConfig = '/api/material/material_category/detail_config',
  getMaterialCategoryList = '/api/material/material_category/list',
  getMaterialCategoryDetail = '/api/material/material_category/detail',
  addMaterialCategory = '/api/material/material_category/add',
  updateMaterialCategory = '/api/material/material_category/update',
  changeMaterialCategoryStatus = '/api/material/material_category/change_status',
  getMaterialCategoryAttributeOption = '/api/material/material_category/attribute_option',
  getMaterialCategoryAttributeList = '/api/material/material_category/attribute_list',
  saveMaterialCategoryAttribute = '/api/material/material_category/save_attribute',
  getMaterialCategoryInspectionOption = '/api/material/material_category/inspection_option',
  getMaterialCategoryInspectionList = '/api/material/material_category/inspection_list',
  saveMaterialCategoryInspection = '/api/material/material_category/save_inspection',
}

export const getMaterialCategoryFilter = (): Promise<any> => {
  return plmRequest.get({ url: Api.getMaterialCategoryFilter })
}

export const getMaterialCategoryDetailConfig = (): Promise<any> => {
  return plmRequest.get({ url: Api.getMaterialCategoryDetailConfig })
}

export const getMaterialCategoryList = (params): Promise<any> => {
  return plmRequest.get({ url: Api.getMaterialCategoryList, params })
}

export const getMaterialCategoryDetail = (params): Promise<any> => {
  return plmRequest.get({ url: Api.getMaterialCategoryDetail, params })
}

export const addMaterialCategory = (data): Promise<any> => {
  return plmRequest.post({ url: Api.addMaterialCategory, data })
}

export const updateMaterialCategory = (data): Promise<any> => {
  return plmRequest.post({ url: Api.updateMaterialCategory, data })
}

export const changeMaterialCategoryStatus = (data): Promise<any> => {
  return plmRequest.post({ url: Api.changeMaterialCategoryStatus, data })
}

export const getMaterialCategoryAttributeOption = (): Promise<any> => {
  return plmRequest.get({ url: Api.getMaterialCategoryAttributeOption })
}

export const getMaterialCategoryAttributeList = (params): Promise<any> => {
  return plmRequest.get({ url: Api.getMaterialCategoryAttributeList, params })
}

export const saveMaterialCategoryAttribute = (params): Promise<any> => {
  return plmRequest.post({ url: Api.saveMaterialCategoryAttribute, params })
}

export const getMaterialCategoryInspectionOption = (): Promise<any> => {
  return plmRequest.get({ url: Api.getMaterialCategoryInspectionOption })
}

export const getMaterialCategoryInspectionList = (params): Promise<any> => {
  return plmRequest.get({ url: Api.getMaterialCategoryInspectionList, params })
}

export const saveMaterialCategoryInspection = (params): Promise<any> => {
  return plmRequest.post({ url: Api.saveMaterialCategoryInspection, params })
}
